import {
  ActionButton,
  ComboBox,
  Content,
  ContextualHelp,
  Flex,
  Heading,
  Item,
  Text,
  Tooltip,
  TooltipTrigger,
} from "@adobe/react-spectrum";
import {
  getEnvironmentTimeZone,
  getLocalizedTimeZoneName,
} from "@quran-wird-bot/utils";
import Location from "@spectrum-icons/workflow/Location";
import { useShowPopup } from "@vkruglikov/react-telegram-web-app";
import { uniqueBy } from "remeda";
import { useTrpc } from "#api/trpc";

export function TimeZoneSelect({
  disabled,
  value: currentTimeZone,
  onChange,
}: {
  disabled?: boolean;
  value: string | null | undefined | (string | null)[];
  onChange: (value: string | null) => void;
}) {
  const { trpcReact: t, pTrpc } = useTrpc();
  const [timeZones] = t.system.timeZones.useSuspenseQuery();

  const formattedTimeZoneNames = uniqueBy(
    timeZones.map((timeZone) => {
      const timeZoneName = (() => {
        try {
          return (
            getLocalizedTimeZoneName({ timeZone, locale: "ar-SY" }) ?? timeZone
          );
        } catch {
          return timeZone;
        }
      })();

      return [timeZone, timeZoneName, `${timeZoneName} (${timeZone})`];
    }),
    ([, , key]) => key,
  ).sort((a, b) => (b[1] && a[1]?.localeCompare(b[1])) || 0);

  const showPopup = useShowPopup();

  const errorCallback: PositionErrorCallback = () => {
    showPopup({
      message:
        "لم نستطع تحديد منطقتك الزمنية بناء على موقعك الجغرافي من جهازك. ربّما يكون السبب أنّك لم تُعطنا إذنًا بذلك.",
    });
  };

  const isLocationSupported =
    typeof navigator.geolocation?.getCurrentPosition === "function";
  const detectedTimeZone = (() => {
    try {
      return getEnvironmentTimeZone();
    } catch {
      return null;
    }
  })();
  const isTimeZoneDetectionSupported =
    detectedTimeZone && timeZones.includes(detectedTimeZone);

  return (
    <Flex gap="size-100" alignItems="end">
      <ComboBox
        label="المنطقة الزمنية"
        selectedKey={
          Array.isArray(currentTimeZone) ? currentTimeZone[0] : currentTimeZone
        }
        isDisabled={disabled}
        contextualHelp={
          <ContextualHelp>
            <Heading>المنطقة الزمنيّة</Heading>
            <Content>
              يُستخدم هذا الخيار لتحديد أوقات التذكير بالمهمّات والواجبات وقت
              نهاية اليوم لاحتساب المخالفات. ينطبق التغيير هنا على جميع
              المجموعات التي أنت عضوٌ فيها.
              <br />
              يمكنك أيضًا إرسال رسالة بموقعك الجغرافي إلى البوت أو ضمن أيّة من
              مجموعاتك لتحديث المنطقة الزمنية تلقائيًّا بناء على موقعك.
            </Content>
          </ContextualHelp>
        }
        width="100%"
        {...(Array.isArray(currentTimeZone) && {
          placeholder: "(المنطقة الزمنية مختلفة بحسب المجموعة)",
        })}
        onSelectionChange={(newValue) => {
          onChange(newValue ? String(newValue) : null);
        }}
      >
        {formattedTimeZoneNames.map(([timeZone, timeZoneName]) => {
          return (
            <Item key={timeZone} hasChildItems textValue={timeZoneName}>
              <Text>{timeZoneName}</Text>
              <Text slot="description">{timeZone}</Text>
            </Item>
          );
        })}
      </ComboBox>
      {(isLocationSupported || isTimeZoneDetectionSupported) && (
        <TooltipTrigger>
          <ActionButton
            marginEnd="auto"
            isDisabled={!(isLocationSupported || isTimeZoneDetectionSupported)}
            onPress={() => {
              if (isTimeZoneDetectionSupported) {
                onChange(detectedTimeZone);
                return;
              }

              navigator.geolocation.getCurrentPosition(
                async ({ coords: { latitude, longitude } }) => {
                  const newValue =
                    await pTrpc.system.timeZoneFromLocation.query({
                      latitude,
                      longitude,
                    });

                  onChange(newValue ?? null);
                },
                errorCallback,
              );
            }}
          >
            <Location />
          </ActionButton>
          <Tooltip>
            استخدام{" "}
            {isTimeZoneDetectionSupported
              ? "المنطقة الزمنية"
              : "الموقع الجغرافي"}{" "}
            من جهازي
          </Tooltip>
        </TooltipTrigger>
      )}
    </Flex>
  );
}

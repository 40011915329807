import type { AppRouter, Token } from "@quran-wird-bot/tg-bot";
import {
  type TRPCLink,
  createTRPCClient,
  createTRPCProxyClient,
  httpBatchLink,
} from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import { observable } from "@trpc/server/observable";
import { atom, computed, react } from "signia";
import { useValue } from "signia-react";
import superjson from "superjson";

const storedToken = localStorage.getItem("auth-token");
const initialToken = storedToken ? JSON.parse(storedToken) : null;

export const tokenAtom = atom<Token | null>("Auth Token", initialToken);

react("sync token to local storage", () => {
  const token = tokenAtom.value;
  if (token && token.type !== "telegram-embedded") {
    localStorage.setItem("auth-token", JSON.stringify(token));
  } else {
    localStorage.removeItem("auth-token");
  }
});

export const customLink: TRPCLink<AppRouter> = () => {
  // here we just got initialized in the app - this happens once per app
  // useful for storing cache for instance
  return ({ next, op }) => {
    // this is when passing the result to the next link
    // each link needs to return an observable which propagates results
    return observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          observer.next(value);
        },
        error(err) {
          if (err.data?.code === "UNAUTHORIZED") {
            tokenAtom.set(null);
          }
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
      return unsubscribe;
    });
  };
};

export const trpcClients = computed("TRPC clients", () => {
  const sharedLinkConfig = {
    url:
      import.meta.env.REACT_APP_API_URL ||
      `https://${location.hostname.replace("mini-app-qbot", "api-qbot")}`,
    headers() {
      return {
        ...(tokenAtom.value && {
          Authorization: `${tokenAtom.value.type} ${tokenAtom.value.token}`,
        }),
      };
    },
  };

  const link = httpBatchLink({
    ...sharedLinkConfig,
  });

  const pLink = httpBatchLink({
    ...sharedLinkConfig,
  });

  const sharedClientConfig = {
    transformer: superjson,
  };

  const pTrpc = createTRPCProxyClient<AppRouter>({
    ...sharedClientConfig,
    links: [customLink, link],
  });

  const trpc = createTRPCClient<AppRouter>({
    ...sharedClientConfig,
    links: [customLink, pLink],
  });

  const trpcReact = createTRPCReact<AppRouter>();

  return { trpcReact, trpc, pTrpc };
});

export function useTrpc() {
  return useValue(trpcClients);
}

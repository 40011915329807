import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import memoize from "lodash.memoize";

import type { TodoAny } from "./types";

export function getLocalDateYYYYMMDD(date: Date, timeZone: string) {
  const localDate = formatInTimeZone(date, timeZone, "yyyy-MM-dd");

  return localDate;
}

export function getEnvironmentTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

interface GetLocalizedTimeZoneNameOptions {
  timeZone: string;
  locale?: string;
}

const getTimeZoneNameFormatter = memoize(
  (locale: string, timeZone: string) =>
    new Intl.DateTimeFormat(locale, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "numeric",
      weekday: "short",
      timeZoneName: "long",
      timeZone,
    }),
  (locale, timeZone) => `${locale}-${timeZone}`,
);

export function getLocalizedTimeZoneName({
  timeZone,
  locale = "ar-SY",
}: GetLocalizedTimeZoneNameOptions) {
  const fmt = getTimeZoneNameFormatter(locale, timeZone);

  const date = new Date();
  const value =
    fmt.formatToParts(date).find((part) => part.type === "timeZoneName")
      ?.value ?? timeZone;

  return value;
}

export function convertToTimeZone(dateInput: Date, timeZone: string) {
  return utcToZonedTime(zonedTimeToUtc(dateInput.getTime(), "UTC"), timeZone);
}

export function parseFromTimeZone(dateString: string, timeZone: string): Date {
  return zonedTimeToUtc(dateString, timeZone);
}

type DateInput = Date | number;

function calcZonedDate(
  date: DateInput,
  tz: string,
  fn: (input: DateInput, options?: TodoAny) => Date,
  options = null,
) {
  const inputZoned = utcToZonedTime(date, tz);
  const fnZoned = options ? fn(inputZoned, options) : fn(inputZoned);
  return zonedTimeToUtc(fnZoned, tz);
}

export function getZonedStartOfDay(date: Date, timeZone: string) {
  return calcZonedDate(date, timeZone, startOfDay);
}

export function getZonedStartOfMonth(date: Date, timeZone: string) {
  return calcZonedDate(date, timeZone, startOfMonth);
}

export function getZonedStartOfYear(date: Date, timeZone: string) {
  return calcZonedDate(date, timeZone, startOfYear);
}

export function getZonedEndOfDay(date: Date, timeZone: string) {
  return calcZonedDate(date, timeZone, endOfDay);
}

export function getZonedStartOfWeek(date: Date, timeZone: string) {
  return calcZonedDate(date, timeZone, (input) =>
    startOfWeek(input, { weekStartsOn: 6 }),
  );
}

export function getZonedEndOfWeek(date: Date, timeZone: string) {
  return calcZonedDate(date, timeZone, (input) =>
    endOfWeek(input, { weekStartsOn: 6 }),
  );
}

export function getZonedEndOfMonth(date: Date, timeZone: string) {
  return calcZonedDate(date, timeZone, endOfMonth);
}

export function getZonedEndOfYear(date: Date, timeZone: string) {
  return calcZonedDate(date, timeZone, endOfYear);
}

import { Content, Flex, View } from "@adobe/react-spectrum";
import { BackButton, useExpand } from "@vkruglikov/react-telegram-web-app";
import { useLayoutEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Boundary } from "../molecules/Loading";

export function Layout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [, expand] = useExpand();
  const [wasExpandedOnce, setWasExpandedOnce] = useState(false);

  useLayoutEffect(() => {
    if (wasExpandedOnce) {
      return;
    }
    expand();
    setWasExpandedOnce(true);
  }, [wasExpandedOnce, expand]);

  return (
    <>
      <Content height="100%">
        <View height="100%">
          <Flex
            direction="column"
            height="100%"
            alignItems="stretch"
            gap="size-100"
          >
            <Boundary>
              <Outlet />
            </Boundary>
          </Flex>
        </View>
      </Content>
      {pathname !== "/" && pathname !== "/groups" && (
        <BackButton onClick={() => navigate(-1)} />
      )}
    </>
  );
}

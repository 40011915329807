import { H, Section } from "react-headings";
import { useTrpc } from "#api/trpc";
import { GroupsList } from "./GroupsList";

export function AdminGroups() {
  const { trpcReact: t } = useTrpc();
  const [groups] = t.admin.groups.useSuspenseQuery();

  return (
    <Section component={<H>المجموعات</H>}>
      <GroupsList groups={groups} />
    </Section>
  );
}

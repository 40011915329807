import {
  Content,
  Heading,
  IllustratedMessage,
  Item,
  ListView,
  Text,
} from "@adobe/react-spectrum";
import type { CHAT_APP_TYPE } from "@quran-wird-bot/shared";
import NoSearchResults from "@spectrum-icons/illustrations/NoSearchResults";
import { useHref, useNavigate } from "react-router-dom";
import { GroupIcon } from "./GroupIcon";

interface GroupsListProps {
  groups: Array<{
    id: number;
    name: string | null | undefined;
    appType: CHAT_APP_TYPE;
  }>;
}
export function GroupsList({ groups }: GroupsListProps) {
  const href = useHref("");
  const navigate = useNavigate();

  return (
    <>
      {groups.length === 0 && (
        <IllustratedMessage marginY="size-1000">
          <NoSearchResults />
          <Heading>لا مجموعات</Heading>
          <Content>عذرًا، لم نجد أيّة مجموعات يمكنك الاطّلاع عليها</Content>
        </IllustratedMessage>
      )}
      {groups.length > 0 && (
        <ListView
          onAction={(id) => navigate(`${href}/${id}`, { replace: false })}
        >
          {groups.map((group) => {
            return (
              <Item key={group.id} hasChildItems textValue={String(group.id)}>
                <GroupIcon width="24" appType={group.appType} />
                <Text>{group.name ?? "(بلا اسم)"}</Text>
              </Item>
            );
          })}
        </ListView>
      )}
    </>
  );
}

import {
  Button,
  Content,
  Dialog,
  DialogContainer,
  Flex,
  Text,
  View,
} from "@adobe/react-spectrum";
import { CHAT_APP_TYPE } from "@quran-wird-bot/shared";
import type { Token } from "@quran-wird-bot/tg-bot";
import { ToastQueue } from "@react-spectrum/toast";
import { Formik } from "formik";
import { useState } from "react";
import { unique } from "remeda";
import { useValue } from "signia-react";
import { tokenAtom, useTrpc } from "#api/trpc";
import { MainButton } from "#components/molecules/MainButton";
import { GroupIcon } from "./GroupIcon";
import { LoginWithWhatsApp } from "./LoginWithWhatsApp";
import { TimeZoneSelect } from "./TimeZoneSelect";
import { loginWithTelegram } from "./loginWithTelegram";

export function UserTimeZoneSetting() {
  const { trpcReact: t } = useTrpc();
  const [{ users: myUsers, userChatAppsMappings }, { refetch }] =
    t.me.get.useSuspenseQuery();
  const token = useValue(tokenAtom);
  const { mutateAsync: updateMe } = t.me.update.useMutation();
  const { mutateAsync: unlinkAccounts } = t.me.unlinkAccounts.useMutation();
  const { mutateAsync: linkAccounts } = t.me.linkAccounts.useMutation({
    async onSuccess() {
      ToastQueue.positive("تم ربط الحسابين", {
        timeout: 3000,
      });
      await refetch();
    },
    onError() {
      ToastQueue.negative("فشل ربط الحسابين", {
        timeout: 3000,
      });
    },
  });

  const myTimeZones = unique(myUsers.map((me) => me.timeZone));
  const myTimeZone = myTimeZones[0];
  const [isWhatsAppLoginModalOpen, setIsWhatsAppLoginModalOpen] =
    useState(false);

  return (
    <>
      {isWhatsAppLoginModalOpen && (
        <WhatsAppLoginModal
          onSuccess={(token) => {
            linkAccounts(token);
          }}
          onClose={() => setIsWhatsAppLoginModalOpen(false)}
        />
      )}
      <View>
        {userChatAppsMappings?.map(({ whatsappId, telegramId }) => {
          return (
            <Flex
              key={`${whatsappId}-${telegramId}`}
              alignItems="center"
              justifyContent="space-between"
            >
              <View>
                <GroupIcon appType={CHAT_APP_TYPE.MULTI_CHAT_APP} width={24} />
                حساب واتساب بالرقم{" "}
                <Text>‎+{whatsappId?.replace("@s.whatsapp.net", "")}</Text> مرتبط
                بحساب تليغرام <Text>{telegramId}</Text>
              </View>
              <Button
                variant="negative"
                onPress={async () => {
                  try {
                    await unlinkAccounts();

                    ToastQueue.positive("تم فكّ الارتباط", {
                      timeout: 3000,
                    });
                    await refetch();
                  } catch {
                    ToastQueue.negative("فشل فكّ الارتباط", {
                      timeout: 3000,
                    });
                  }
                }}
              >
                فكّ الارتباط
              </Button>
            </Flex>
          );
        })}
        {!userChatAppsMappings?.length && (
          <Flex alignItems="center" justifyContent="space-between">
            <View>
              <GroupIcon appType={CHAT_APP_TYPE.MULTI_CHAT_APP} width={24} />
              {token?.type === "whatsapp"
                ? "لم يتمّ ربط حساب تلغرام"
                : "لم يتمّ ربط حساب واتساب"}
            </View>
            <Button
              variant="secondary"
              onPress={async () => {
                try {
                  if (!token?.type) {
                    return;
                  }

                  if (token.type === "whatsapp") {
                    const token = await loginWithTelegram();
                    await linkAccounts(token);

                    return;
                  }

                  setIsWhatsAppLoginModalOpen(true);
                } catch {
                  ToastQueue.negative("فشل ربط الحسابين", {
                    timeout: 3000,
                  });
                }
              }}
            >
              ربط حساب
            </Button>
          </Flex>
        )}
      </View>
      <Formik
        initialValues={{ timeZone: myTimeZone }}
        onSubmit={async ({ timeZone }) => {
          try {
            if (!timeZone) {
              return;
            }
            await updateMe({ timeZone });
            ToastQueue.positive("تم تحديث المنطقة الزمنية", {
              timeout: 3000,
            });
            await refetch();
          } catch {
            ToastQueue.negative("فشل تحديث المنطقة الزمنية", {
              timeout: 3000,
            });
          }
        }}
      >
        {({
          submitForm,
          setFieldValue,
          values,
          isSubmitting,
          isValid,
          dirty,
          touched,
        }) => (
          <>
            <TimeZoneSelect
              onChange={(value) => setFieldValue("timeZone", value)}
              value={values.timeZone}
              disabled={isSubmitting}
            />
            <MainButton
              text="حفظ التغييرات"
              progress={isSubmitting}
              disabled={isSubmitting || !dirty || !touched || !isValid}
              onClick={() => {
                submitForm();
              }}
            />
          </>
        )}
      </Formik>
    </>
  );
}

function WhatsAppLoginModal({
  onSuccess,
  onClose,
}: {
  onSuccess: (token: Token) => void;
  onClose: () => void;
}) {
  return (
    <DialogContainer onDismiss={onClose}>
      <Dialog onDismiss={onClose}>
        <Content>
          <LoginWithWhatsApp
            onSuccess={(data) => {
              onSuccess({ token: data, type: "whatsapp" });
              onClose();
            }}
          />
        </Content>
      </Dialog>
    </DialogContainer>
  );
}

import type { PropsWithChildren } from "react";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { TimelineItem } from "./TimelineItem";

interface TimelineProps extends PropsWithChildren {}

export function Timeline({ children }: TimelineProps) {
  return (
    <VerticalTimeline
      layout="1-column-right"
      className="vertical-timeline-element--work"
    >
      {children}
    </VerticalTimeline>
  );
}

Timeline.Item = TimelineItem;

import {
  ActionButton,
  Flex,
  Text,
  TimeField,
  Tooltip,
  TooltipTrigger,
  View,
} from "@adobe/react-spectrum";
import { Time } from "@internationalized/date";
import Add from "@spectrum-icons/workflow/Add";
import DeleteIcon from "@spectrum-icons/workflow/Delete";
import { Fragment, useMemo } from "react";
import { last } from "remeda";
import { joinTimes } from "../../utils/joinTimes";
import { parseTimes } from "../../utils/parseTimes";

export interface MultiTimeFieldProps {
  value?: string | null;
  label?: string;
  description?: string;
  name?: string;
  onChange: (value: string) => void;
  maxFields?: number;
  minFields?: number;
  additionalContent?: React.ReactNode;
  isDisabled?: boolean;
}

export function MultiTimeField({
  value,
  label,
  description,
  name,
  onChange,
  maxFields,
  minFields,
  additionalContent,
  isDisabled,
}: MultiTimeFieldProps) {
  const times = useMemo(() => {
    if (!value) {
      return [null] as const;
    }
    return parseTimes(value) ?? [];
  }, [value]);
  const lastTime = last(times);
  const newSuggestedTime = useMemo(
    () => (lastTime ?? new Time(10, 0))?.cycle("hour", 1),
    [lastTime],
  );

  return (
    <>
      {times.map((time, i, a) => {
        const canAdd = !(
          isDisabled ||
          (typeof maxFields === "number" && times.length >= maxFields) ||
          times.some((time) => time?.compare(newSuggestedTime) === 0)
        );
        const addTime = () => {
          onChange(joinTimes([...times, newSuggestedTime]));
        };

        const deleteTime = () => {
          if (time === null) {
            return;
          }
          onChange(joinTimes([...times.slice(0, i), ...times.slice(i + 1)]));
        };

        const canDelete = !(
          isDisabled ||
          time === null ||
          (typeof minFields === "number" && times.length <= minFields)
        );

        return (
          <Fragment key={`${i + 1}`}>
            {i === 0 && (
              <View marginTop="size-200" UNSAFE_className="LabelTextContainer">
                <Text UNSAFE_className="LabelText" slot="description">
                  {label}
                </Text>
              </View>
            )}

            {i === 0 && description && (
              <div className="HelpTextContainer">
                <Text UNSAFE_className="HelpText" slot="description">
                  {description}
                </Text>
              </div>
            )}
            {i === 0 && additionalContent}
            <Flex alignItems="end" gap="size-100">
              {time !== null && (
                <TimeField
                  isDisabled={isDisabled}
                  UNSAFE_className="TimeField-ltr"
                  value={time}
                  name={name ? `${name}[${i}]` : undefined}
                  onChange={(value) => {
                    const newTimes = [...times];
                    newTimes[i] = value;
                    onChange(joinTimes(newTimes));
                  }}
                />
              )}
              {time !== null && (
                <ActionButton
                  aria-label="حذف الوقت"
                  isDisabled={!canDelete}
                  onPress={deleteTime}
                >
                  <DeleteIcon size="S" aria-label="حذف" />
                </ActionButton>
              )}
              {i === a.length - 1 &&
                (time !== null ? (
                  <TooltipTrigger>
                    <ActionButton
                      aria-label="إضافة وقت جديد"
                      isDisabled={!canAdd}
                      onPress={addTime}
                    >
                      <Add size="S" aria-label="إضافة" />
                    </ActionButton>
                    <Tooltip>إضافة وقت جديد</Tooltip>
                  </TooltipTrigger>
                ) : (
                  <ActionButton
                    aria-label="إضافة وقت جديد"
                    isDisabled={!canAdd}
                    onPress={addTime}
                  >
                    <Add size="S" aria-label="إضافة" />
                    <Text>إضافة وقت جديد</Text>
                  </ActionButton>
                ))}
            </Flex>
          </Fragment>
        );
      })}
    </>
  );
}

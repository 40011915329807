import {
  ActionButton,
  ActionMenu,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Flex,
  Form,
  Heading,
  Item,
  ListView,
  Text,
  TextField,
} from "@adobe/react-spectrum";
import { getLocalizedTimeZoneName } from "@quran-wird-bot/utils";
import { ToastQueue } from "@react-spectrum/toast";
import Delete from "@spectrum-icons/workflow/Delete";
import GlobeClock from "@spectrum-icons/workflow/GlobeClock";
import MoveTo from "@spectrum-icons/workflow/MoveTo";
import { Formik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTrpc } from "#api/trpc";
import { TimeZoneSelect } from "./TimeZoneSelect";

function UserRenameDialog({
  isOpen,
  onClose,
  user,
}: { isOpen: boolean; onClose: () => void; user: User | null }) {
  const { trpcReact: t } = useTrpc();
  const { mutateAsync: updateUser } = t.admin.updateGroupUser.useMutation();

  if (!user) {
    return null;
  }

  return (
    <DialogTrigger isDismissable={false} isOpen={isOpen} onOpenChange={onClose}>
      <ActionButton isHidden={true}>تغيير اسم المستخدم</ActionButton>
      {(close) => (
        <Dialog>
          <Formik
            initialValues={{
              name: user.name,
            }}
            onSubmit={async (values) => {
              close();
              try {
                await updateUser({
                  userId: user.id,
                  name: values.name || undefined,
                });
                ToastQueue.positive("تم تغيير اسم المستخدم", { timeout: 3000 });
              } catch {
                ToastQueue.negative("فشل تغيير اسم المستخدم", {
                  timeout: 3000,
                });
              }
            }}
          >
            {({
              submitForm,
              isSubmitting,
              dirty,
              touched,
              values,
              setFieldValue,
              isValid,
            }) => (
              <>
                <Heading>تغيير اسم المستخدم</Heading>
                <Divider />
                <Content>
                  <Form>
                    <Flex direction="column">
                      <Text>أدخل الاسم الجديد</Text>
                      <TextField
                        name="name"
                        value={values.name || ""}
                        onChange={(value) => setFieldValue("name", value)}
                      />
                    </Flex>
                  </Form>
                </Content>
                <ButtonGroup>
                  <Button
                    isDisabled={isSubmitting}
                    variant="secondary"
                    onPress={close}
                  >
                    إلغاء الأمر
                  </Button>
                  <Button
                    isDisabled={isSubmitting || !touched || !dirty || !isValid}
                    variant="accent"
                    onPress={submitForm}
                  >
                    حفظ التغييرات
                  </Button>
                </ButtonGroup>
              </>
            )}
          </Formik>
        </Dialog>
      )}
    </DialogTrigger>
  );
}

function UserTimeZoneDialog({
  isOpen,
  onClose,
  user,
}: { isOpen: boolean; onClose: () => void; user: User | null }) {
  const { trpcReact: t } = useTrpc();
  const { mutateAsync: updateUser } = t.admin.updateGroupUser.useMutation();

  if (!user) {
    return null;
  }

  return (
    <DialogTrigger isDismissable={false} isOpen={isOpen} onOpenChange={onClose}>
      <ActionButton isHidden={true}>
        تغيير المنطقة الزمنية للمستخدم
      </ActionButton>
      {(close) => (
        <Dialog>
          <Formik
            initialValues={{
              timeZone: user.timeZone,
            }}
            onSubmit={async (values) => {
              close();
              try {
                await updateUser({
                  userId: user.id,
                  timeZone: values.timeZone ?? undefined,
                });
                ToastQueue.positive("تم تغيير المنطقة الزمنية للمستخدم", {
                  timeout: 3000,
                });
              } catch {
                ToastQueue.negative("فشل تغيير المنطقة الزمنية للمستخدم", {
                  timeout: 3000,
                });
              }
            }}
          >
            {({
              submitForm,
              isSubmitting,
              dirty,
              touched,
              values,
              setFieldValue,
            }) => (
              <>
                <Heading>تغيير المنطقة الزمنية للمستخدم</Heading>
                <Divider />
                <Content>
                  <Form>
                    <Flex direction="column">
                      <Text>أدخل المنطقة الزمنية الجديدة</Text>
                      <TimeZoneSelect
                        value={values.timeZone}
                        onChange={(value) => setFieldValue("timeZone", value)}
                        disabled={isSubmitting}
                      />
                    </Flex>
                  </Form>
                </Content>
                <ButtonGroup>
                  <Button
                    isDisabled={isSubmitting}
                    variant="secondary"
                    onPress={close}
                  >
                    إلغاء الأمر
                  </Button>
                  <Button
                    isDisabled={isSubmitting || !touched || !dirty}
                    variant="accent"
                    onPress={submitForm}
                  >
                    حفظ التغييرات
                  </Button>
                </ButtonGroup>
              </>
            )}
          </Formik>
        </Dialog>
      )}
    </DialogTrigger>
  );
}

type User = { id: number; name: string | null; timeZone: string | null };

export function GroupUsers() {
  const { groupId } = useParams();
  const { trpcReact: t } = useTrpc();
  const [data, { refetch }] = t.admin.groupUsers.useSuspenseQuery({
    groupId: Number(groupId),
  });
  const [openRenameUser, setOpenRenameUser] = useState<User | null>(null);
  const [openTimeZoneUser, setOpenTimeZoneUser] = useState<User | null>(null);

  return (
    <div>
      <UserRenameDialog
        user={openRenameUser}
        isOpen={openRenameUser !== null}
        onClose={() => {
          setOpenRenameUser(null);
          refetch();
        }}
      />
      <UserTimeZoneDialog
        user={openTimeZoneUser}
        isOpen={openTimeZoneUser !== null}
        onClose={() => {
          setOpenTimeZoneUser(null);
          refetch();
        }}
      />
      <ListView>
        {data.map((user) => {
          return (
            <Item
              key={user.id}
              hasChildItems={true}
              textValue={String(user.id)}
            >
              <Text>{user.name}</Text>
              <Text slot="description">
                {user?.phoneNumber && (
                  <>
                    <Text>
                      <span dir="ltr">{user?.phoneNumber}</span>
                    </Text>
                    {"  ⦁  "}
                  </>
                )}
                <Text>
                  {user.timeZone
                    ? getLocalizedTimeZoneName({
                        timeZone: user.timeZone,
                        locale: "ar-SY",
                      })
                    : "المنطقة الزمنية غير محدّدة"}
                </Text>
              </Text>
              <ActionMenu
                onAction={(id) => {
                  if (id === "rename") {
                    setOpenRenameUser(user);
                    return;
                  }

                  if (id === "edit-timezone") {
                    setOpenTimeZoneUser(user);
                    return;
                  }
                }}
              >
                <Item key="edit-timezone" textValue="تعديل المنقطة الزمنية">
                  <GlobeClock />
                  <Text>تعديل المنطقة الزمنية</Text>
                </Item>
                <Item key="rename" textValue="تغيير اسم المستخدم">
                  <MoveTo />
                  <Text>تغيير اسم المستخدم</Text>
                </Item>
                <Item
                  key="move-read-log-entry"
                  textValue="نقل تسجيل قراءة من يوم إلى آخر"
                  href={`/admin/users/${user.id}/move-read-entry`}
                >
                  <MoveTo />
                  <Text>نقل تسجيل قراءة من يوم إلى آخر</Text>
                </Item>
                <Item
                  key="delete-strike"
                  textValue="حذف مخالفة"
                  href={`/admin/users/${user.id}/delete-strike`}
                >
                  <Delete />
                  <Text>حذف مخالفة</Text>
                </Item>
              </ActionMenu>
            </Item>
          );
        })}
      </ListView>
    </div>
  );
}

import { Form, Switch } from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { assert } from "ts-essentials";
import { useTrpc } from "#api/trpc";
import { MainButton } from "#components/molecules/MainButton";
import { MultiDurationField } from "#components/molecules/MultiDurationField";
import { MultiTimeField } from "#components/molecules/MultiTimeField";
import { LocalePicker } from "./LocalePicker";

export function UserSettings() {
  const { groupId } = useParams();
  const { trpcReact: t } = useTrpc();

  const [userSettingsForGroup, { refetch }] =
    t.me.userSettingsForGroup.useSuspenseQuery({
      groupId: Number(groupId),
    });
  const [defaults] = t.me.groupSettings.useSuspenseQuery({
    groupId: Number(groupId),
  });
  const { mutateAsync } = t.me.updateUserSettingsForGroup.useMutation();

  const { locale, reminderToPerformDutyTimes, reminderToReadTimes } =
    userSettingsForGroup;

  assert(groupId, "groupId is required");

  return (
    <Formik
      initialValues={{
        groupId: Number(groupId),
        reminderToPerformDutyTimes,
        reminderToReadTimes,
        locale,
      }}
      onSubmit={async (values, { setValues }) => {
        try {
          await mutateAsync(values);
          ToastQueue.positive("تم حفظ التغييرات", { timeout: 3000 });
        } catch {
          ToastQueue.negative("فشل حفظ التغييرات", { timeout: 3000 });
        } finally {
          const newResults = await refetch();
          setValues({ ...values, ...newResults });
        }
      }}
    >
      {({
        submitForm,
        isSubmitting,
        dirty,
        setFieldValue,
        values,
        touched,
      }) => (
        <Form minWidth={300} maxWidth={400}>
          <LocalePicker
            defaultValue={defaults.locale}
            label="اللغة"
            value={values.locale}
            onChange={(locale) => setFieldValue("locale", locale)}
          />
          <MultiTimeField
            minFields={1}
            maxFields={Math.max(3, defaults.reminderToReadTimes.length)}
            isDisabled={values.reminderToReadTimes === null}
            name="reminderToReadTimes"
            label="وقت التذكير بالمهمّات المطلوبة يوميًا"
            value={
              values.reminderToReadTimes ??
              defaults.reminderToReadTimes.join(",")
            }
            onChange={(value) => {
              setFieldValue("reminderToReadTimes", value);
            }}
            additionalContent={
              <Switch
                isSelected={values.reminderToReadTimes === null}
                onChange={(isSelected) => {
                  setFieldValue(
                    "reminderToReadTimes",
                    isSelected
                      ? null
                      : userSettingsForGroup.reminderToReadTimes ??
                          defaults.reminderToReadTimes.join(","),
                  );
                }}
              >
                استخدم الإعدادات المبدئيّة
              </Switch>
            }
          />

          <MultiDurationField
            minFields={1}
            maxFields={Math.max(3, defaults.reminderToPerformDutyTimes.length)}
            isDisabled={values.reminderToPerformDutyTimes === null}
            name="reminderToPerformDutyTimes"
            label="وقت التذكير بأداء الواجبات"
            value={
              values.reminderToPerformDutyTimes ??
              defaults.reminderToPerformDutyTimes.join(",")
            }
            onChange={(value) => {
              setFieldValue("reminderToPerformDutyTimes", value);
            }}
            additionalContent={
              <Switch
                isSelected={values.reminderToPerformDutyTimes === null}
                onChange={(isSelected) => {
                  setFieldValue(
                    "reminderToPerformDutyTimes",
                    isSelected
                      ? null
                      : userSettingsForGroup.reminderToPerformDutyTimes ??
                          defaults.reminderToPerformDutyTimes.join(","),
                  );
                }}
              >
                استخدم الإعدادات المبدئيّة
              </Switch>
            }
          />

          <MainButton
            text="حفظ التغييرات"
            progress={isSubmitting}
            disabled={isSubmitting || !dirty || !touched}
            onClick={() => {
              submitForm();
            }}
          />
        </Form>
      )}
    </Formik>
  );
}

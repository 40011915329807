import { NavLink, useParams } from "react-router-dom";
import { useTrpc } from "#api/trpc";
import { Timeline } from "./Timeline";

export function User() {
  const { userId } = useParams();
  const { trpcReact: t } = useTrpc();
  const [data] = t.admin.groupUser.useSuspenseQuery({ userId: Number(userId) });

  return (
    <div className="content">
      <div>{data.name ?? data.appUsername}</div>
      <div>{data.timeZone}</div>
      <div>
        <NavLink to={`/admin/users/${userId}/move-read-entry`}>
          نقل تسجيل قراءة إلى يوم آخر
        </NavLink>
      </div>
      <NavLink to={`/admin/users/${userId}/delete-strike`}>حذف مخالفة</NavLink>
      <Timeline userId={userId} />
    </div>
  );
}

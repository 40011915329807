import type { PropsWithChildren } from "react";
import { FormattedDate } from "react-intl";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import "./TimelineItem.css";

interface TimelineItemProps extends PropsWithChildren {
  timestamp: Date;
}

export function TimelineItem({ timestamp, children }: TimelineItemProps) {
  return (
    <VerticalTimelineElement className="vertical-timeline-element--work timeline-item">
      {children}
      <div className="timeline-item-datetime" datatype="datetime">
        <FormattedDate
          weekday="long"
          day="numeric"
          month="long"
          year="numeric"
          value={timestamp}
        />
      </div>
    </VerticalTimelineElement>
  );
}

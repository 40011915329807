import "./App.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { oneLine } from "common-tags";
import { IntlProvider } from "react-intl";
import { useTrpc } from "#api/trpc";
import { RouterProvider } from "react-router-dom";
import { track } from "signia-react";
import { router } from "./router";

const queryClient = new QueryClient();

export const App = track(function App() {
  const { trpcReact: t, trpc } = useTrpc();

  return (
    <IntlProvider
      messages={{
        "user.timeline.event": oneLine`
          {type, select,
              StrikeCreated {تمّت إضافة مخالفة}
              DutyResolved {تمّت تأدية الواجب}
              DutyCreated {تمّت إضافة واجب}
              ReadLogCreated {تم إضافة تسجيل قراءة}
              DutyBecameDue {أصبح الواجب مستحقاً}
              other {حدث من نوع غير معروف}
          }`,
      }}
      locale="ar-SY"
      defaultLocale="ar-SY"
    >
      <t.Provider client={trpc} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </t.Provider>
    </IntlProvider>
  );
});

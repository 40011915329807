import {
  Link,
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { AdminGroups } from "./AdminGroups";
import { GroupAdvanced } from "./GroupAdvanced";
import { GroupPage } from "./GroupPage";
import { GroupSettings } from "./GroupSettings";
import { GroupTimeline } from "./GroupTimeline";
import { GroupUsers } from "./GroupUsers";
import { Layout } from "./Layout";
import { NoMatch } from "./NoMatch";
import { User } from "./User";
import { UserDeleteStrike } from "./UserDeleteStrike";
import { UserMoveReadEntry } from "./UserMoveReadEntry";

import { useTrpc } from "#api/trpc";
import { AppContent } from "./AppContent";
import { Login } from "./Login";
import { NonAdminGroups } from "./NonAdminGroups";
import { UserPage } from "./UserPage";
import { UserSettings } from "./UserSettings";

function NonAdminLayout() {
  const { trpcReact: t } = useTrpc();
  const [{ isAdminOfAtLeastOneGroup }] = t.me.get.useSuspenseQuery();

  return (
    <div>
      {isAdminOfAtLeastOneGroup && <Link to="/admin">لوحة التحكم</Link>}
      <Layout />
    </div>
  );
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppContent />} path="/">
      <Route path="login" element={<Login />} />
      <Route path="admin" element={<Layout />}>
        <Route
          index
          element={<Navigate to="groups" relative="route" replace />}
        />
        <Route path="users">
          <Route path=":userId">
            <Route index element={<User />} />
            <Route path="delete-strike" element={<UserDeleteStrike />} />
            <Route path="move-read-entry" element={<UserMoveReadEntry />} />
          </Route>
        </Route>
        <Route path="groups">
          <Route index element={<AdminGroups />} />
          <Route path=":groupId" element={<GroupPage />}>
            <Route
              index
              element={<Navigate relative="path" to="users" replace />}
            />
            <Route path="users">
              <Route index element={<GroupUsers />} />
            </Route>
            <Route path="timeline" element={<GroupTimeline />} />
            <Route path="settings" element={<GroupSettings />} />
            <Route path="advanced" element={<GroupAdvanced />} />
          </Route>
        </Route>
      </Route>
      <Route path="" element={<NonAdminLayout />}>
        <Route
          index
          element={<Navigate to="groups" relative="route" replace />}
        />
        <Route path="groups">
          <Route index element={<NonAdminGroups />} />
          <Route path=":groupId" element={<UserPage />}>
            <Route
              index
              element={<Navigate relative="path" to="settings" replace />}
            />
            <Route path="settings" element={<UserSettings />} />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<NoMatch />} />
    </Route>,
  ),
  {
    future: {
      v7_relativeSplatPath: true,
    },
  },
);

import { ComboBox, Flex, Item, Text, TextField } from "@adobe/react-spectrum";
import { MCaptchaWidget } from "@mcaptcha/react-glue";
import { Formik } from "formik";
import { OTPInput, type SlotProps } from "input-otp";
import { useMemo, useRef, useState } from "react";
import { useTrpc } from "#api/trpc";
import { MainButton } from "#components/molecules/MainButton";

export function LoginWithWhatsApp({
  onSuccess,
}: { onSuccess: (token: string) => void }) {
  const countryFormatter = useMemo(
    () =>
      new Intl.DisplayNames("ar", {
        type: "region",
      }),
    [],
  );
  const { trpcReact: t } = useTrpc();
  const [step, setStep] = useState<"request" | "verify">("request");

  const formRef = useRef<HTMLFormElement>(null);
  const [countries] = t.system.supportedLoginCountryCodes.useSuspenseQuery();
  const { mutateAsync: requestCode } = t.auth.requestLoginCode.useMutation({
    onSuccess(data) {
      if (data) {
        setStep("verify");
      }
    },
  });

  const { mutateAsync: loginWithCode } = t.auth.loginWithCode.useMutation({
    onSuccess(data) {
      if (data) {
        onSuccess(data);
      }
    },
  });

  const locale = "ar-SY";

  return (
    <Formik
      initialValues={{
        countryCallingCode: "",
        phoneNumber: "",
        code: "",
        mcaptcha__token: "",
      }}
      onSubmit={(values) => {
        const phoneNumber = `+${
          values.countryCallingCode
        }${values.phoneNumber.replace(/^0/, "")}`;

        if (step === "request") {
          return requestCode({
            provider: "whatsapp",
            locale,
            phoneNumber,
            mcaptchaToken:
              new FormData(formRef.current ?? undefined)
                .get("mcaptcha__token")
                ?.toString() ?? "",
          });
        }

        if (step === "verify") {
          return loginWithCode({
            provider: "whatsapp",
            phoneNumber,
            code: values.code,
          });
        }
      }}
    >
      {({
        submitForm,
        isSubmitting,
        dirty,
        touched,
        values,
        setFieldValue,
      }) => (
        <form ref={formRef}>
          <Flex direction="row-reverse">
            <ComboBox
              onSelectionChange={(key) =>
                setFieldValue("countryCallingCode", key)
              }
              selectedKey={values.countryCallingCode}
            >
              {countries.map(({ callingCode, country }) => {
                const label = countryFormatter.of(country);
                const text = `‎+${callingCode} ${label}`;
                return (
                  <Item key={callingCode} textValue={text}>
                    <Text>{text}</Text>
                  </Item>
                );
              })}
            </ComboBox>
            <TextField
              name="phoneNumber"
              value={values.phoneNumber}
              placeholder={
                countries.find(
                  ({ callingCode }) =>
                    callingCode === values.countryCallingCode,
                )?.example
              }
              onChange={(value) => setFieldValue("phoneNumber", value)}
            />
          </Flex>
          {step === "verify" && (
            <OTPInput
              onChange={(value) => setFieldValue("code", value)}
              maxLength={6}
              render={({ slots }) => (
                <div
                  style={{
                    direction: "ltr",
                    flexWrap: "nowrap",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      direction: "ltr",
                      flexWrap: "nowrap",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    {slots.slice(0, 3).map((slot, idx) => (
                      <Slot key={String(idx)} {...slot} />
                    ))}
                  </div>

                  <FakeDash />

                  <div
                    style={{
                      direction: "ltr",
                      flexWrap: "nowrap",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    {slots.slice(3).map((slot, idx) => (
                      <Slot key={String(idx)} {...slot} />
                    ))}
                  </div>
                </div>
              )}
            />
          )}
          <MCaptchaWidget
            siteKey={{
              key: import.meta.env.REACT_APP_MCAPTCHA_SITEKEY,
              instanceUrl: new URL(
                import.meta.env.REACT_APP_MCAPTCHA_INSTANCE_URL,
              ),
            }}
          />
          <MainButton
            text="حفظ التغييرات"
            progress={isSubmitting}
            disabled={isSubmitting || !dirty || !touched}
            onClick={() => {
              submitForm();
            }}
          />
        </form>
      )}
    </Formik>
  );
}

// Feel free to copy. Uses @shadcn/ui tailwind colors.
function Slot(props: SlotProps) {
  return (
    <div
      style={{ width: 50, height: 50, background: "white" }}
      // className={cn(
      //   "relative w-10 h-14 text-[2rem]",
      //   "flex items-center justify-center",
      //   "transition-all duration-300",
      //   "border-border border-y border-r first:border-l first:rounded-l-md last:rounded-r-md",
      //   "group-hover:border-accent-foreground/20 group-focus-within:border-accent-foreground/20",
      //   "outline outline-0 outline-accent-foreground/20",
      //   { "outline-4 outline-accent-foreground": props.isActive },
      // )}
    >
      {props.char !== null && <div>{props.char}</div>}
      {props.hasFakeCaret && <FakeCaret />}
    </div>
  );
}

// You can emulate a fake textbox caret!
function FakeCaret() {
  return (
    <div className="absolute pointer-events-none inset-0 flex items-center justify-center animate-caret-blink">
      <div className="w-px h-8 bg-white" />
    </div>
  );
}

// Inspired by Stripe's MFA input.
function FakeDash() {
  return (
    <div className="flex w-10 justify-center items-center">
      <div className="w-3 h-1 rounded-full bg-border" />
    </div>
  );
}

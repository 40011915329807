import {
  ActionButton,
  Flex,
  Item,
  NumberField,
  Picker,
  Text,
  TimeField,
  View,
} from "@adobe/react-spectrum";
import { Time } from "@internationalized/date";
import { parseDurations } from "@quran-wird-bot/utils";
import Add from "@spectrum-icons/workflow/Add";
import DeleteIcon from "@spectrum-icons/workflow/Delete";
import { differenceInMinutes, endOfDay, startOfDay } from "date-fns";
import { apply, isNegative, normalize, subtract } from "duration-fns";
import { Fragment, useMemo } from "react";
import {
  entries,
  fromEntries,
  isDeepEqual,
  last,
  map,
  omit,
  pick,
  pipe,
} from "remeda";
import { joinDurations } from "#utils/joinDurations";

export interface MultiDurationFieldProps {
  value?: string | null;
  label?: string;
  description?: string;
  name?: string;
  onChange: (value: string) => void;
  maxFields?: number;
  minFields?: number;
  additionalContent?: React.ReactNode;
  isDisabled?: boolean;
}

export function MultiDurationField({
  value,
  label,
  description,
  name,
  onChange,
  maxFields,
  minFields,
  additionalContent,
  isDisabled,
}: MultiDurationFieldProps) {
  const durations = useMemo(() => {
    if (!value) {
      return [null] as const;
    }
    return parseDurations(value);
  }, [value]);
  const lastTime = last(durations);
  const newSuggestedDuration = useMemo(
    () =>
      normalize(
        lastTime
          ? { ...lastTime, hours: (lastTime.hours ?? 0) + 1 }
          : { days: 1, hours: 10, minutes: 30 },
      ),
    [lastTime],
  );

  return (
    <>
      {durations.map((duration, i, a) => {
        const canAdd = !(
          isDisabled ||
          (typeof maxFields === "number" && durations.length >= maxFields) ||
          durations.some((duration) =>
            duration
              ? isDeepEqual(newSuggestedDuration, normalize(duration))
              : false,
          )
        );
        const addDuration = () => {
          onChange(joinDurations([...durations, newSuggestedDuration]));
        };

        const deleteDuration = () => {
          if (duration === null) {
            return;
          }
          onChange(
            joinDurations([
              ...durations.slice(0, i),
              ...durations.slice(i + 1),
            ]),
          );
        };

        const canDelete = !(
          isDisabled ||
          duration === null ||
          (typeof minFields === "number" && durations.length <= minFields)
        );

        const dayWithTimeApplied = duration
          ? apply(endOfDay(new Date()), pick(duration, ["hours", "minutes"]))
          : null;

        return (
          <Fragment key={`${i + 1}`}>
            {i === 0 && (
              <View marginTop="size-200" UNSAFE_className="LabelTextContainer">
                <Text UNSAFE_className="LabelText" slot="description">
                  {label}
                </Text>
              </View>
            )}

            {i === 0 && description && (
              <div className="HelpTextContainer">
                <Text UNSAFE_className="HelpText" slot="description">
                  {description}
                </Text>
              </div>
            )}
            {i === 0 && additionalContent}
            <Flex wrap="wrap" alignItems="end" gap="size-100">
              {duration !== null && (
                <>
                  <Picker
                    width={duration.days ? 80 : 178}
                    isDisabled={isDisabled}
                    onSelectionChange={(key) => {
                      const newDurations = [...durations];
                      let durationToNegate = normalize({ ...newDurations[i] });

                      if (durationToNegate === null) {
                        return;
                      }
                      if (key === "0") {
                        durationToNegate = subtract(durationToNegate, {
                          days: isNegative(durationToNegate) ? -1 : 1,
                        });
                        durationToNegate.days = 0;
                        newDurations[i] = durationToNegate;
                        onChange(joinDurations(newDurations));
                        return;
                      }
                      const multiplier = key === "-" ? -1 : 1;
                      durationToNegate.days ||= 1;

                      durationToNegate = normalize(
                        pipe(
                          durationToNegate,
                          entries(),
                          map(
                            ([key, value]) =>
                              [
                                key,
                                value === undefined
                                  ? undefined
                                  : Math.abs(value) * multiplier,
                              ] as const,
                          ),
                          fromEntries(),
                        ),
                      );

                      if (durationToNegate.minutes) {
                        durationToNegate.minutes =
                          multiplier * 60 - durationToNegate.minutes;
                      }
                      if (durationToNegate.hours) {
                        durationToNegate.hours =
                          multiplier * 24 - durationToNegate.hours;
                      }

                      newDurations[i] = durationToNegate;
                      onChange(joinDurations(newDurations));
                    }}
                    selectedKey={
                      duration.days ? (duration.days < 0 ? "-" : "+") : "0"
                    }
                  >
                    <Item key="-">قبل</Item>
                    <Item key="0">في اليوم نفسه</Item>
                    <Item key="+">بعد</Item>
                  </Picker>
                  {Boolean(duration.days) && (
                    <NumberField
                      isDisabled={isDisabled}
                      value={Math.abs(duration?.days ?? 0)}
                      width={90}
                      maxValue={7}
                      minValue={1}
                      formatOptions={{
                        style: "unit",
                        unit: "day",
                        // Anything other than `'narrow'` is buggy (maybe just in Arabic?)
                        unitDisplay: "narrow",
                      }}
                      name={name ? `${name}.days[${i}]` : undefined}
                      onChange={(value) => {
                        const newDurations = [...durations];
                        const duration = omit(
                          {
                            ...newDurations[i],
                          },
                          ["years", "months", "weeks"],
                        );
                        const days = isNegative(duration) ? -value : value;

                        // @ts-expect-error partial is okay
                        newDurations[i] = {
                          ...duration,
                          days,
                        };
                        onChange(joinDurations(newDurations));
                      }}
                    />
                  )}
                  <Text alignSelf="center">الساعة</Text>
                  {dayWithTimeApplied && (
                    <TimeField
                      width={100}
                      isDisabled={isDisabled}
                      UNSAFE_className="TimeField-ltr"
                      value={new Time(0, 0).add({
                        minutes:
                          differenceInMinutes(
                            dayWithTimeApplied,
                            startOfDay(dayWithTimeApplied),
                          ) + 1,
                      })}
                      name={name ? `${name}.time[${i}]` : undefined}
                      onChange={(value) => {
                        const newDurations = [...durations];
                        if (!isNegative(duration)) {
                          newDurations[i] = normalize({
                            ...duration,
                            minutes: value.minute,
                            hours: value.hour,
                          });
                          onChange(joinDurations(newDurations));
                          return;
                        }
                        const dateAtValue = apply(
                          startOfDay(dayWithTimeApplied),
                          {
                            minutes: value.minute,
                            hours: value.hour,
                          },
                        );
                        const diff =
                          differenceInMinutes(
                            endOfDay(dateAtValue),
                            dateAtValue,
                          ) + 1;

                        newDurations[i] = normalize({
                          ...omit(duration, ["hours"]),
                          minutes: -diff,
                        });
                        onChange(joinDurations(newDurations));
                      }}
                    />
                  )}
                </>
              )}
              {duration !== null && (
                <ActionButton
                  aria-label="حذف الوقت"
                  isDisabled={!canDelete}
                  onPress={deleteDuration}
                >
                  <DeleteIcon size="S" aria-label="حذف" />
                </ActionButton>
              )}
            </Flex>
            {i === a.length - 1 && (
              <Flex>
                <ActionButton
                  aria-label="إضافة تذكير جديد"
                  isDisabled={!canAdd}
                  onPress={addDuration}
                >
                  <Add size="S" aria-label="إضافة" />
                  <Text>إضافة تذكير جديد</Text>
                </ActionButton>
              </Flex>
            )}
          </Fragment>
        );
      })}
    </>
  );
}

import { Item, TabList, TabPanels, Tabs, View } from "@adobe/react-spectrum";
import { H, Section } from "react-headings";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { last } from "remeda";
import { useTrpc } from "#api/trpc";
import { Boundary } from "../molecules/Loading";
import { GroupIcon } from "./GroupIcon";

interface ItemType {
  id: string;
  title: string;
}

export function GroupPage() {
  const { groupId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { trpcReact: t } = useTrpc();
  const [group] = t.admin.group.useSuspenseQuery({ groupId: Number(groupId) });

  return (
    <>
      <Section
        component={
          <H>
            <GroupIcon appType={group.appType} width="36" />
            {group.name ?? "(بلا اسم)"}
          </H>
        }
      >
        <Tabs<ItemType>
          items={[
            {
              id: "timeline",
              title: "السجل الزمني",
            },
            {
              id: "users",
              title: "المستخدمون",
            },
            {
              id: "settings",
              title: "الإعدادات",
            },
            {
              id: "advanced",
              title: "الخيارات المتقدمة",
            },
          ]}
          selectedKey={last(pathname.split("/"))}
          onSelectionChange={(key) =>
            navigate(String(key), {
              relative: "path",
              replace: key === "timeline",
            })
          }
        >
          <TabList<ItemType>>
            {(item) => {
              return <Item>{item.title}</Item>;
            }}
          </TabList>
          <TabPanels flexGrow={1}>
            {() => (
              <Item>
                <View height="100%" paddingTop={16}>
                  <Boundary>
                    <Outlet />
                  </Boundary>
                </View>
              </Item>
            )}
          </TabPanels>
        </Tabs>
      </Section>
    </>
  );
}

import {
  Content,
  Flex,
  Heading,
  IllustratedMessage,
  ProgressCircle,
} from "@adobe/react-spectrum";
import Unavailable from "@spectrum-icons/illustrations/Unavailable";
import { Suspense } from "react";

import { ErrorBoundary } from "react-error-boundary";

export function Loading() {
  return (
    <Flex
      flexGrow={1}
      height="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <ProgressCircle alignSelf="center" aria-label="يُحمّل..." isIndeterminate />
    </Flex>
  );
}

function OwnErrorBoundary() {
  return (
    <IllustratedMessage marginY="size-1000">
      <Unavailable />
      <Heading>آسفون!</Heading>
      <Content>
        عذرًا، لم نستطع عرض هذه الصفحة. ربّما يكون السبب عُطلًا أصاب الخادوم لدينا
        أو أنّ أذوناتك لا تسمح بالوصول إلى البيانات المطلوبة.
      </Content>
    </IllustratedMessage>
  );
}

export function Boundary({ children }: React.PropsWithChildren) {
  return (
    <Suspense fallback={<Loading />}>
      <ErrorBoundary fallback={<OwnErrorBoundary />}>{children}</ErrorBoundary>
    </Suspense>
  );
}

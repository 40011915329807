import {
  type DateValue,
  endOfMonth,
  parseDate,
  startOfMonth,
} from "@internationalized/date";
import type { ExplicitAny } from "@quran-wird-bot/utils";
import { useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";

interface UseAutoLoadForDatePickerOptions<Data extends ExplicitAny[]> {
  query({ from, to }: { from: string; to: string }): Promise<Awaited<Data>>;
  filter?(dataPage: undefined | Data, dateValue: DateValue): boolean;
}

export function useAutoLoadForDatePicker<Data extends ExplicitAny[]>({
  query,
  filter = (dataPage, dateValue) =>
    !dataPage?.some((strike) => strike.forLocalDate === dateValue.toString()),
}: UseAutoLoadForDatePickerOptions<Data>) {
  const client = useQueryClient();
  type data = Awaited<Data>;
  const pages = useRef<Record<string, undefined | data | Promise<data>>>({});
  const load = async (start: string) => {
    const resultPromise = client
      .getMutationCache()
      .build(client, {
        mutationFn: () => {
          const from = startOfMonth(parseDate(start)).toString();
          const to = endOfMonth(parseDate(start)).toString();

          return query({ from, to });
        },
      })
      .execute();

    pages.current[start] = resultPromise;
    resultPromise
      .then((result) => {
        pages.current[start] = result;
      })
      .catch((_error) => {
        pages.current[start] = undefined;
      });
    return resultPromise;
  };

  return {
    load,
    pages,
    isDateUnavailable(dateValue: DateValue) {
      const start = startOfMonth(dateValue).toString();
      const dataPage = pages.current[start];

      if (!dataPage) {
        load(start);

        return false;
      }

      if (dataPage instanceof Promise) {
        return false;
      }

      return filter(dataPage, dateValue);
    },
  };
}

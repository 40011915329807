import { Provider, defaultTheme } from "@adobe/react-spectrum";
import { ToastContainer } from "@react-spectrum/toast";
import { WebAppProvider } from "@vkruglikov/react-telegram-web-app";
import React from "react";
import ReactDOM from "react-dom/client";
import { assert } from "ts-essentials";
import { Boundary } from "./components/molecules/Loading";
import { App } from "./components/organisms/App";

const element = document.getElementById("root");

assert(element);

const root = ReactDOM.createRoot(element);

root.render(
  <React.StrictMode>
    <Provider theme={defaultTheme} locale="ar-SY">
      <ToastContainer />
      <WebAppProvider
        options={{
          smoothButtonsTransition: true,
          async: true,
        }}
      >
        <Boundary>
          <App />
        </Boundary>
      </WebAppProvider>
    </Provider>
  </React.StrictMode>,
);

import type { Token } from "@quran-wird-bot/tg-bot";
import type { WebAppUser } from "@twa-dev/types";

export function loginWithTelegram() {
  return new Promise<Token>((resolve, reject) => {
    Telegram.Login.auth(
      { bot_id: Number(import.meta.env.REACT_APP_BOT_TELEGRAM_ID) },
      (user: WebAppUser) => {
        const params = new URLSearchParams();
        for (const [key, value] of Object.entries(user)) {
          params.append(key, value);
        }
        const token = params.toString();
        if (token) {
          resolve({
            type: "telegram-standalone",
            token,
          });

          return;
        }

        reject(new TypeError("No token found"));
      },
    );
  });
}

import { Button } from "@adobe/react-spectrum";
import { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useTrpc } from "#api/trpc";
import { Timeline as BaseTimeline } from "#components/molecules/Timeline";

interface TimelineProps {
  groupId?: number | string;
  userId?: number | string;
  appId?: string;
}

export function Timeline({ groupId, userId, appId }: TimelineProps) {
  const { trpcReact: t } = useTrpc();
  const [{ pages }, { fetchNextPage }] =
    t.admin.timeline.useSuspenseInfiniteQuery(
      {
        appId,
        userId: userId ? Number(userId) : undefined,
        groupId: groupId ? Number(groupId) : undefined,
      },
      {
        getNextPageParam(lastPage) {
          return lastPage.cursor;
        },
      },
    );

  const showUserDetails = userId === undefined;
  const showGroupDetails = showUserDetails && groupId === undefined;

  return (
    <BaseTimeline>
      {pages
        .flatMap((page) =>
          page.data.map((d) => ({ ...d, cursor: page.cursor, page })),
        )
        .map(
          (
            { id, type, userId, groupId, timestamp, cursor, page },
            i,
            array,
          ) => {
            const isLast = i === array.length - 1;

            return (
              <Fragment key={id}>
                <BaseTimeline.Item timestamp={timestamp}>
                  <FormattedMessage
                    id="user.timeline.event"
                    values={{ type }}
                  />
                  {showUserDetails && (
                    <div>
                      المستخدم: {page.users.get(userId)?.name ?? userId}
                    </div>
                  )}
                  {showGroupDetails && (
                    <div>
                      المجموعة: {page.groups.get(groupId)?.name ?? groupId}
                    </div>
                  )}
                </BaseTimeline.Item>
                {isLast && cursor !== undefined && (
                  <Button onPress={() => fetchNextPage()} variant="secondary">
                    الصفحة التالية
                  </Button>
                )}
              </Fragment>
            );
          },
        )}
    </BaseTimeline>
  );
}

import {
  Content,
  ContextualHelp,
  Heading,
  Item,
  Picker,
  Switch,
  Text,
  View,
} from "@adobe/react-spectrum";
import { useTrpc } from "#api/trpc";

interface LocalePickerProps {
  value: string | null;
  defaultValue: string;
  onChange: (value: string | null) => void;
  disabled?: boolean;
  label: string;
  description?: string;
}

export function LocalePicker({
  label,
  description,
  value,
  defaultValue,
  onChange,
}: LocalePickerProps) {
  const { trpcReact: t } = useTrpc();
  const [supportedLocales] = t.system.supportedLocales.useSuspenseQuery();

  return (
    <>
      <View marginTop="size-200" UNSAFE_className="LabelTextContainer">
        <Text UNSAFE_className="LabelText" slot="description">
          {label}
        </Text>
      </View>

      {description && (
        <div className="HelpTextContainer">
          <Text UNSAFE_className="HelpText" slot="description">
            {description}
          </Text>
        </div>
      )}
      <Switch
        isSelected={value === null}
        onChange={(isSelected) => {
          onChange(isSelected ? null : value ?? defaultValue);
        }}
      >
        استخدم الإعدادات المبدئيّة
      </Switch>
      <Picker
        name="locale"
        isDisabled={value === null}
        selectedKey={value ?? defaultValue}
        onSelectionChange={(key) => {
          if (value === null) {
            return;
          }
          onChange(String(key));
        }}
        contextualHelp={
          <ContextualHelp>
            <Heading>لغة المجموعات</Heading>
            <Content>
              يُحدّد هذا الإعداد اللغة التي يتمّ فيها عرض الرسائل والتذكيرات. قد
              يختار المُستخدمون لغة مختلفة تُرسل بها الرسائل الخاصّة إليهم.
            </Content>
          </ContextualHelp>
        }
        aria-label={label}
        items={supportedLocales.map((locale) => ({
          key: locale,
          name: new Intl.DisplayNames(locale, { type: "language" }).of(locale),
        }))}
      >
        {({ key, name }) => <Item key={key}>{name}</Item>}
      </Picker>
    </>
  );
}

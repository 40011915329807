import {
  Button,
  Cell,
  Column,
  Item,
  Picker,
  Row,
  TableBody,
  TableHeader,
  TableView,
  Text,
  View,
} from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import { useState } from "react";
import { H, Section } from "react-headings";
import { useParams } from "react-router-dom";
import { assert } from "ts-essentials";
import { useTrpc } from "#api/trpc";
import { GroupIcon } from "./GroupIcon";

export function GroupAdvanced() {
  const { groupId } = useParams();
  const { trpcReact: t } = useTrpc();

  assert(groupId, "groupId is required");

  const { mutateAsync: updateMessage } =
    t.admin.updateOrCreateDailyReportMessage.useMutation();

  const { mutateAsync: mergeGroups } = t.admin.mergeGroups.useMutation();

  const [groups] = t.admin.mergeableGroups.useSuspenseQuery({
    groupId: Number(groupId),
  });
  const [intoGroupUsers] = t.admin.mergeableGroupUsers.useSuspenseQuery({
    groupId: Number(groupId),
  });
  const [sourceGroupId, setSourceGroupId] = useState<null | number>(null);
  const [userMap, setUserMap] = useState<Map<number, { into: number }>>(
    new Map(),
  );
  const hasSourceGroup = sourceGroupId !== null;
  const { data: sourceGroupUsers = [] } = t.admin.mergeableGroupUsers.useQuery(
    { groupId: Number(sourceGroupId) },
    { enabled: hasSourceGroup },
  );

  return (
    <>
      <View>
        <Button
          variant="accent"
          onPress={async () => {
            try {
              await updateMessage({ groupId: Number(groupId) });
              ToastQueue.positive("تم تحديث الرسالة", { timeout: 3000 });
            } catch {
              ToastQueue.negative("فشل تحديث الرسالة", { timeout: 3000 });
            }
          }}
        >
          تحديث رسالة التقرير اليومي
        </Button>
      </View>

      {groups.length > 0 && (
        <Section component={<H>دمج مجموعة مع هذه</H>}>
          <Picker
            label="المجموعة التي تودّ دمجها مع هذه المجموعة"
            selectedKey={String(sourceGroupId)}
            onSelectionChange={(newGroupId) =>
              setSourceGroupId(Number(newGroupId) as number)
            }
            width="size-3600"
          >
            {groups.map((group) => {
              return (
                <Item key={group.id}>
                  <Text>
                    <View flex width="100%">
                      <GroupIcon appType={group.appType} width={12} />
                      {group.name} {group.appId}
                    </View>
                  </Text>
                </Item>
              );
            })}
          </Picker>
          <View marginBottom="size-100">
            {Boolean(intoGroupUsers?.length) && (
              <TableView>
                <TableHeader>
                  <Column>المستخدم في المجموعة الهدف</Column>
                  <Column>المستخدم الموافق</Column>
                </TableHeader>
                <TableBody>
                  {intoGroupUsers?.map((intoUser) => {
                    return (
                      <Row key={intoUser.id}>
                        <Cell>{intoUser.name}</Cell>
                        <Cell>
                          <Picker
                            width="size-3600"
                            isDisabled={sourceGroupId === null}
                            selectedKey={[...userMap.entries()]
                              .find(
                                ([_, value]) => value.into === intoUser.id,
                              )?.[0]
                              ?.toString()}
                            onSelectionChange={(newUserId) => {
                              setUserMap((prev) => {
                                const newMap = new Map(prev);
                                newMap.set(Number(newUserId), {
                                  into: Number(intoUser.id) as number,
                                });
                                return newMap;
                              });
                            }}
                            placeholder={
                              sourceGroupId === null
                                ? "اختر المجموعة أولًا"
                                : undefined
                            }
                          >
                            {(sourceGroupUsers ?? []).map((user) => {
                              return (
                                <Item key={user.id}>
                                  <Text>
                                    {user.name} ({user.appId})
                                  </Text>
                                </Item>
                              );
                            })}
                          </Picker>
                        </Cell>
                      </Row>
                    );
                  })}
                </TableBody>
              </TableView>
            )}
          </View>
          <Button
            variant="accent"
            onPress={async () => {
              try {
                await mergeGroups({
                  groupId: Number(sourceGroupId),
                  intoGroupId: Number(groupId),
                  userMap,
                });
                ToastQueue.positive("تم دمج المجموعتين", { timeout: 3000 });
              } catch {
                ToastQueue.negative("فشل دمج المجموعتين", { timeout: 3000 });
              }
            }}
          >
            دمج المجموعتين
          </Button>
        </Section>
      )}
    </>
  );
}

import type { SVGAttributes } from "react";

import { CHAT_APP_TYPE } from "@quran-wird-bot/shared";
import MultiAppIcon from "#icons/multi.svg?react";
import TelegramIcon from "#icons/telegram.svg?react";
import WhatsAppIcon from "#icons/whatsapp.svg?react";

const iconsByGroupType: Record<
  CHAT_APP_TYPE,
  React.ComponentType<SVGAttributes<SVGElement>>
> = {
  [CHAT_APP_TYPE.WHATSAPP]: WhatsAppIcon,
  [CHAT_APP_TYPE.TELEGRAM]: TelegramIcon,
  [CHAT_APP_TYPE.MULTI_CHAT_APP]: MultiAppIcon,
};

interface GroupIconProps extends SVGAttributes<SVGElement> {
  appType: CHAT_APP_TYPE;
}
export function GroupIcon({ appType, className, ...rest }: GroupIconProps) {
  const Icon = iconsByGroupType[appType];

  if (!Icon) {
    return null;
  }
  return (
    <Icon className={`${className ?? ""} group-icon _${appType}`} {...rest} />
  );
}

import { H, Section } from "react-headings";
import { useTrpc } from "#api/trpc";
import { GroupsList } from "./GroupsList";
import { UserTimeZoneSetting } from "./UserTimeZoneSetting";

export function NonAdminGroups() {
  const { trpcReact: t } = useTrpc();
  const [groups] = t.me.groups.useSuspenseQuery();

  return (
    <>
      <Section component={<H>حسابي</H>}>
        <UserTimeZoneSetting />
      </Section>
      <Section component={<H>المجموعات</H>}>
        <GroupsList groups={groups} />
      </Section>
    </>
  );
}

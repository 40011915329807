import { useInitData as useInitDataTg } from "@vkruglikov/react-telegram-web-app";
import { useLayoutEffect } from "react";
import { tokenAtom } from "../trpc";

export function useSyncInitData() {
  const [, initData] = useInitDataTg();

  useLayoutEffect(() => {
    if (initData) {
      tokenAtom.set({ type: "telegram-embedded", token: initData });
    }
  }, [initData]);
}

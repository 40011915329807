import {
  Checkbox,
  Content,
  ContextualHelp,
  Form,
  Heading,
  Item,
  Picker,
  Slider,
  Switch,
  Text,
  View,
} from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { assert } from "ts-essentials";
import { useTrpc } from "#api/trpc";
import { MainButton } from "#components/molecules/MainButton";
import { MultiDurationField } from "#components/molecules/MultiDurationField";
import { MultiTimeField } from "#components/molecules/MultiTimeField";
import { LocalePicker } from "./LocalePicker";

export function GroupSettings() {
  const { groupId } = useParams();

  const { trpcReact: t } = useTrpc();
  const [group, { refetch }] = t.admin.group.useSuspenseQuery({
    groupId: Number(groupId),
  });
  const [defaults] = t.system.defaults.useSuspenseQuery();
  const { mutateAsync } = t.admin.updateGroup.useMutation();

  const {
    kind,
    numDaysDutyDueAfter,
    reminderToPerformDutyTimes,
    reminderToReadTimes,
    strikesPerDuty,
    sendReminderMessagesToPrivateChat,
    locale,
  } = group;

  assert(groupId, "groupId is required");

  return (
    <Formik
      initialValues={{
        groupId: Number(groupId),
        kind,
        numDaysDutyDueAfter: numDaysDutyDueAfter ?? 7,
        strikesPerDuty: strikesPerDuty ?? 5,
        reminderToPerformDutyTimes,
        reminderToReadTimes,
        sendReminderMessagesToPrivateChat,
        locale,
      }}
      onSubmit={async (values, { setValues }) => {
        try {
          await mutateAsync(values);
          ToastQueue.positive("تم حفظ التغييرات", { timeout: 3000 });
        } catch {
          ToastQueue.negative("فشل حفظ التغييرات", { timeout: 3000 });
        } finally {
          const newResults = await refetch();
          setValues({ ...values, ...newResults });
        }
      }}
    >
      {({
        submitForm,
        isSubmitting,
        dirty,
        setFieldValue,
        setValues,
        values,
        touched,
      }) => (
        <Form minWidth={300} maxWidth={400}>
          <LocalePicker
            defaultValue={defaults.locale}
            label="اللغة"
            value={values.locale}
            onChange={(locale) => setFieldValue("locale", locale)}
          />
          <Picker
            name="kind"
            selectedKey={String(values.kind)}
            onSelectionChange={(key) => {
              setValues({ ...values, kind: Number(key) });
            }}
            contextualHelp={
              <ContextualHelp>
                <Heading>أنواع المجموعات</Heading>
                <Content>
                  يُحدّد نوع المجموعة العبارات التي يتعرّف عليها البوت كإنجاز
                  للمهمّة، مثلًا: إذا أرسل المستخدم 'قرأت ما تيسر من الجزء ٢٤' في
                  مجموعة القرآن
                </Content>
              </ContextualHelp>
            }
            label="نوع المجموعة"
            items={[
              { key: "1", name: "قرآن" },
              { key: "2", name: "أذكار" },
            ]}
          >
            {({ key, name }) => <Item key={key}>{name}</Item>}
          </Picker>
          <Slider
            name="numDaysDutyDueAfter"
            label="عدد الأيام المتاحة لتأدية الواجب"
            value={values.numDaysDutyDueAfter ?? 7}
            minValue={2}
            maxValue={14}
            onChange={(value) => {
              setFieldValue("numDaysDutyDueAfter", value);
            }}
          />
          <Slider
            name="strikesPerDuty"
            label="عدد المخالفات المطلوبة لاحتساب واجب جديد"
            value={values.strikesPerDuty}
            maxValue={10}
            minValue={2}
            onChange={(value) => {
              setFieldValue("strikesPerDuty", value);
            }}
          />

          <Checkbox
            isSelected={values.sendReminderMessagesToPrivateChat}
            onChange={(value) => {
              setFieldValue("sendReminderMessagesToPrivateChat", value);
            }}
            name="sendReminderMessagesToPrivateChat"
          >
            <Text>
              إرسال رسائل التذكير إلى الدردشة الخاصة بالمستخدم بدلًا من المجموعة
            </Text>
            <View marginTop="size-200" UNSAFE_className="HelpTextContainer">
              <Text UNSAFE_className="HelpText" slot="description">
                في حال فشل إرسال الرسالة إلى الدردشة الخاصّة، بسبب حظر المستخدم
                للبوت أو عدم مراسلته من قبل، سيتم إرسال الرسالة إلى المجموعة.
              </Text>
            </View>
          </Checkbox>

          <MultiTimeField
            minFields={1}
            maxFields={Math.max(3, defaults.reminderToReadTimes.length)}
            isDisabled={values.reminderToReadTimes === null}
            name="reminderToReadTimes"
            label="وقت التذكير بالمهمّات المطلوبة يوميًا"
            description="هذه الأوقات تنطبق على المستخدمين الذين يُضافون لاحقًا إلى المجموعة، وعلى المستخدمين الذين لم يحدّدوا أوقاتًا مخصّصة له. يتبع التوقيت المحدّد المنطقة الزمنية لكلّ مستخدم على حدة."
            value={
              values.reminderToReadTimes ??
              defaults.reminderToReadTimes.join(",")
            }
            onChange={(value) => {
              setFieldValue("reminderToReadTimes", value);
            }}
            additionalContent={
              <Switch
                isSelected={values.reminderToReadTimes === null}
                onChange={(isSelected) => {
                  setFieldValue(
                    "reminderToReadTimes",
                    isSelected
                      ? null
                      : group.reminderToReadTimes ??
                          defaults.reminderToReadTimes.join(","),
                  );
                }}
              >
                استخدم الإعدادات المبدئيّة
              </Switch>
            }
          />

          <MultiDurationField
            minFields={1}
            maxFields={Math.max(3, defaults.reminderToPerformDutyTimes.length)}
            isDisabled={values.reminderToPerformDutyTimes === null}
            name="reminderToPerformDutyTimes"
            label="وقت التذكير بأداء الواجبات"
            description="هذه الأوقات تنطبق على المستخدمين الذين يُضافون لاحقًا إلى المجموعة، وعلى المستخدمين الذين لم يحدّدوا أوقاتًا مخصّصة له. يتبع التوقيت المحدّد المنطقة الزمنية لكلّ مستخدم على حدة."
            value={
              values.reminderToPerformDutyTimes ??
              defaults.reminderToPerformDutyTimes.join(",")
            }
            onChange={(value) => {
              setFieldValue("reminderToPerformDutyTimes", value);
            }}
            additionalContent={
              <Switch
                isSelected={values.reminderToPerformDutyTimes === null}
                onChange={(isSelected) => {
                  setFieldValue(
                    "reminderToPerformDutyTimes",
                    isSelected
                      ? null
                      : group.reminderToPerformDutyTimes ??
                          defaults.reminderToPerformDutyTimes.join(","),
                  );
                }}
              >
                استخدم الإعدادات المبدئيّة
              </Switch>
            }
          />

          <MainButton
            text="حفظ التغييرات"
            progress={isSubmitting}
            disabled={isSubmitting || !dirty || !touched}
            onClick={() => {
              submitForm();
            }}
          />
        </Form>
      )}
    </Formik>
  );
}

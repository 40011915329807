import { Button } from "@adobe/react-spectrum";
import { MainButton as TgMainButton } from "@vkruglikov/react-telegram-web-app";
import type { ComponentProps } from "react";
import { isInTelegramContext } from "#utils/isInTelegramContext";

export function MainButton(props: ComponentProps<typeof TgMainButton>) {
  if (!isInTelegramContext()) {
    const { disabled, text, color, progress, textColor, disable, ...rest } =
      props;

    return (
      <Button variant="cta" isDisabled={disabled} {...rest}>
        {text}
      </Button>
    );
  }
  return <TgMainButton {...props} />;
}

export enum CHAT_APP_TYPE {
  TELEGRAM = 1,
  WHATSAPP = 2,
  MULTI_CHAT_APP = 3,
}

export enum MESSAGING_LOG_TARGET_TYPE {
  USER = 1,
  GROUP = 2,
}

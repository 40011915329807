import { Content, Heading, IllustratedMessage } from "@adobe/react-spectrum";
import NotFound from "@spectrum-icons/illustrations/NotFound";

export function NoMatch() {
  return (
    <IllustratedMessage marginY="size-1000">
      <NotFound />
      <Heading>الصفحة غير موجودة</Heading>
      <Content>عذرًا، لم نجد الصفحة التي تبحث عنها</Content>
    </IllustratedMessage>
  );
}

import type { DateTimeDuration } from "@internationalized/date";
import { isTruthy } from "@quran-wird-bot/utils";
import { toString as durationToString, normalize } from "duration-fns";
import { unique } from "remeda";

export function joinDurations(durations: (DateTimeDuration | null)[]) {
  return unique(
    durations
      .filter(isTruthy)
      .map((value) => normalize(value))
      .map((value) => durationToString(value)),
  ).join(",");
}

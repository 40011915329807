import { DatePicker } from "@adobe/react-spectrum";
import { parseDate, today } from "@internationalized/date";
import { ToastQueue } from "@react-spectrum/toast";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTrpc } from "#api/trpc";
import { MainButton } from "#components/molecules/MainButton";
import { useAutoLoadForDatePicker } from "./useAutoLoadForDatePicker";

export function UserDeleteStrike() {
  const { userId } = useParams();
  const { trpcReact: t, pTrpc } = useTrpc();
  const [user] = t.admin.groupUser.useSuspenseQuery({ userId: Number(userId) });
  const [dateToDeleteStrikeFor, setDateToDeleteStrikeFor] = useState(() => {
    if (!user?.timeZone) {
      return "";
    }
    return today(user.timeZone).toString();
  });

  const { mutate } = t.admin.deleteStrike.useMutation();
  const { isDateUnavailable } = useAutoLoadForDatePicker({
    query: ({ from, to }) =>
      pTrpc.admin.getStrikesForUser.query({
        userId: Number(userId),
        range: {
          from,
          to,
        },
      }),
  });

  if (!user?.timeZone) {
    return <div>لم يتم تعيين المنطقة الزمنية لهذا المستخدم</div>;
  }

  return (
    <>
      <DatePicker
        label="يوم المخالفة التي تود حذفها"
        maxValue={today(user.timeZone)}
        isDateUnavailable={isDateUnavailable}
        onChange={(dateValue) => {
          setDateToDeleteStrikeFor(dateValue.toString());
        }}
        value={parseDate(dateToDeleteStrikeFor)}
      />
      <MainButton
        text="تأكيد الحذف"
        onClick={() =>
          mutate(
            {
              userId: Number(userId),
              forLocalDate: dateToDeleteStrikeFor,
            },
            {
              onSuccess: (result) => {
                if (result) {
                  ToastQueue.positive("تم الحذف", { timeout: 3000 });
                } else {
                  ToastQueue.info("لم نجد مخالفة لهذا اليوم", {
                    timeout: 3000,
                  });
                }
              },
              onError: () =>
                ToastQueue.negative("فشل الحذف", { timeout: 3000 }),
            },
          )
        }
      />
    </>
  );
}

import { RouterProvider } from "react-aria-components";
import {
  Navigate,
  Outlet,
  useHref,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useSyncInitData } from "#api/auth/useSyncInitData";
import { useIsLoggedIn } from "./useIsLoggedIn";

export function AppContent() {
  useSyncInitData();
  const isLoggedIn = useIsLoggedIn();
  const { pathname } = useLocation();

  const navigate = useNavigate();

  if (!isLoggedIn) {
    return (
      <RouterProvider navigate={navigate} useHref={useHref}>
        <div className="app">
          <Navigate to="/login" replace />
          {pathname === "/login" && <Outlet />}
        </div>
        <div className="dialog-container" />
      </RouterProvider>
    );
  }

  return (
    <RouterProvider navigate={navigate} useHref={useHref}>
      <div className="app">
        <Outlet />
      </div>
      <div className="dialog-container" />
    </RouterProvider>
  );
}

import { Text } from "@adobe/react-spectrum";
import { Button } from "@adobe/react-spectrum";
import { CHAT_APP_TYPE } from "@quran-wird-bot/shared";
import { useLayoutEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { tokenAtom, useTrpc } from "#api/trpc";
import { GroupIcon } from "./GroupIcon";
import { LoginWithWhatsApp } from "./LoginWithWhatsApp";
import { loginWithTelegram } from "./loginWithTelegram";
import { useIsLoggedIn } from "./useIsLoggedIn";

export function Login() {
  const [params, setParams] = useSearchParams();
  const provider = params.get("provider");
  const { trpcReact: t } = useTrpc();
  const isLoggedIn = useIsLoggedIn();
  const [step, setStep] = useState<"start" | "request" | "verify">("start");
  useLayoutEffect(() => {
    if (provider === "whatsapp") {
      setStep("request");
    } else {
      setStep("start");
    }
  }, [provider]);
  const [loginOptions] = t.auth.loginOptions.useSuspenseQuery();
  const canLoginWithTelegram = loginOptions.includes(CHAT_APP_TYPE.TELEGRAM);
  const canLoginWithWhatsApp = loginOptions.includes(CHAT_APP_TYPE.WHATSAPP);
  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  if (step === "start" || provider !== "whatsapp") {
    return (
      <>
        {canLoginWithTelegram && (
          <Button
            UNSAFE_className="telegram-button"
            variant="cta"
            marginBottom="size-100"
            onPress={async () => {
              try {
                tokenAtom.set(await loginWithTelegram());
              } catch {
                // Do nothing for now
              }
            }}
          >
            <Text>
              <GroupIcon
                width={24}
                appType={CHAT_APP_TYPE.TELEGRAM}
                style={{ color: "white" }}
              />
              تسجيل الدخول باستخدام تيليغرام
            </Text>
          </Button>
        )}
        {canLoginWithWhatsApp && (
          <Button
            UNSAFE_className="whatsapp-button"
            marginBottom="size-100"
            variant="cta"
            onPress={() => {
              setStep("request");
              setParams({ provider: "whatsapp" });
            }}
          >
            <Text>
              <GroupIcon
                width={24}
                appType={CHAT_APP_TYPE.WHATSAPP}
                style={{ color: "white" }}
              />
              تسجيل الدخول باستخدام واتساب
            </Text>
          </Button>
        )}
      </>
    );
  }

  if (provider === "whatsapp" && step === "request") {
    return (
      <LoginWithWhatsApp
        onSuccess={(data) => {
          tokenAtom.set({ token: data, type: "whatsapp" });
        }}
      />
    );
  }

  return null;
}

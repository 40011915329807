import createLogger from "pino";

export const logger = createLogger({
  level: process.env.DEBUG ? "debug" : "info",
  transport: {
    target: "pino-pretty",
  },
});

export const authLogger = createLogger({
  level: "info",
  transport: {
    target: "pino-pretty",
  },
});

type FalsyValue =
  | null
  | undefined
  | false
  | 0
  | ""
  | 0n
  | typeof NaN
  | typeof document.all;

export const isTruthy = <const T>(
  value: T | FalsyValue,
): value is Exclude<T, FalsyValue> => Boolean(value);

// biome-ignore lint/suspicious/noExplicitAny: to be done
export type TodoAny = any;

// biome-ignore lint/suspicious/noExplicitAny: to be used for the whole project
export type ExplicitAny = any;

export function assertUnreachable(x: never): never {
  throw new Error(
    `Didn't expect to get here. ${x} should have been unreachable.`,
  );
}

export type Nullish<T> = T | null | undefined;

export type Lazy<T, Args extends ExplicitAny[] = []> = (
  ...args: Args
) => T | Promise<T>;

export function isNullish<T>(
  value: T | null | undefined,
): value is T | null | undefined {
  return value === null || value === undefined;
}

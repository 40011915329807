import {
  toString as durationToString,
  normalize,
  parse,
  toMinutes,
} from "duration-fns";
import { sortBy } from "remeda";
import { isTruthy } from "./types";

type OptionalStr<T extends string> = T | "";

export type DurationString =
  `${OptionalStr<"-">}P${OptionalStr<`${OptionalStr<`${number}Y`>}${OptionalStr<`${number}M`>}${OptionalStr<`${number}W`>}${OptionalStr<`${number}D`>}`>}T${OptionalStr<`${number}H`>}${OptionalStr<`${number}M`>}${OptionalStr<`${number}S`>}`;

export function isValidDurationString(val: string): val is DurationString {
  try {
    parse(val);
  } catch {
    return false;
  }

  return true;
}

export function parseDurations(value: string | null | undefined) {
  return (value ?? "")
    .split(",")
    .filter(isTruthy)
    .map((durationStr) => {
      return parse(durationStr);
    });
}
export function normalizeDurationsString<T extends string | null | undefined>(
  value: T,
): T {
  if (value === undefined || value === null) {
    return value;
  }

  return sortBy(
    (value ?? "")
      .split(",")
      .filter(isTruthy)
      .map((str) => parse(str))
      .map((dur) => normalize(dur))
      .map((input) => durationToString(input)),
    (str) => toMinutes(str),
  ).join(",") as T;
}

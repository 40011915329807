import { DatePicker } from "@adobe/react-spectrum";
import { today } from "@internationalized/date";
import { ToastQueue } from "@react-spectrum/toast";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTrpc } from "#api/trpc";
import { MainButton } from "#components/molecules/MainButton";
import { useAutoLoadForDatePicker } from "./useAutoLoadForDatePicker";

export function UserMoveReadEntry() {
  const { userId } = useParams();
  const { trpcReact: t, pTrpc } = useTrpc();
  const [user] = t.admin.groupUser.useSuspenseQuery({ userId: Number(userId) });
  const [state, setState] = useState({ from: "2023-12-01", to: "2023-12-02" });
  const { isDateUnavailable } = useAutoLoadForDatePicker({
    query: ({ from, to }) =>
      pTrpc.admin.getReadLogEntriesForUser.query({
        userId: Number(userId),
        range: {
          from,
          to,
        },
      }),
    filter(page, dateValue) {
      return (
        dateValue.toString() === state.to ||
        !page?.some((entry) => entry.localDate === dateValue.toString())
      );
    },
  });
  const { mutate } = t.admin.moveReadLogEntry.useMutation();

  if (!user?.timeZone) {
    return <div>لم يتم تعيين المنطقة الزمنية لهذا المستخدم</div>;
  }

  return (
    <>
      <DatePicker
        label="النقل من يوم"
        maxValue={today(user.timeZone)}
        isDateUnavailable={isDateUnavailable}
        onChange={(dateValue) => {
          setState((state) => ({ ...state, from: dateValue.toString() }));
        }}
      />
      <DatePicker
        label="النقل إلى يوم"
        maxValue={today(user.timeZone)}
        isDateUnavailable={(dateValue) => {
          return dateValue.toString() === state.from;
        }}
        onChange={(dateValue) => {
          setState((state) => ({ ...state, to: dateValue.toString() }));
        }}
      />
      <MainButton
        text="تأكيد النقل"
        onClick={() =>
          mutate(
            {
              userId: Number(userId),
              fromLocalDate: state.from,
              toLocalDate: state.to,
            },
            {
              onSuccess: () =>
                ToastQueue.positive("تم النقل", { timeout: 3000 }),
              onError: () =>
                ToastQueue.negative("فشل النقل", { timeout: 3000 }),
            },
          )
        }
      />
    </>
  );
}

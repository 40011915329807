import type { Time } from "@internationalized/date";
import { isTruthy } from "@quran-wird-bot/utils";
import { unique } from "remeda";

export function joinTimes(times: (Time | null)[]) {
  return unique(
    times
      .filter(isTruthy)
      .map((time) =>
        [
          time.hour.toString().padStart(2, "0"),
          time.minute.toString().padStart(2, "0"),
        ].join(":"),
      ),
  ).join(",");
}

import { useParams } from "react-router-dom";
import { Timeline } from "./Timeline";

export function GroupTimeline() {
  const { groupId } = useParams();

  return (
    <div className="content">
      <Timeline groupId={groupId} />
    </div>
  );
}
